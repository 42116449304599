
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { useRoute, useRouter } from "vue-router";

interface Beneficiary {
  id: number; // Change the type of 'id' accordingly
  name: string; // Change the type of 'name' accordingly
  nid: string; // Change the type of 'nid' accordingly
}

interface OutputOption {
  value: string;
  text: string;
}

interface ActivityOption {
  value: string;
  text: string;
}

interface DistrictOption {
  value: string;
  text: string | undefined;
}

interface UpazilaOption {
  value: string;
  text: string | undefined;
}

export default defineComponent({
  mixins: [apiEndpoint],
  name: "EventForm",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { bus } = useBus();
    const route = useRoute();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id")
        ? VueCookieNext.getCookie("_user_id")
        : 0,
      component_id: "",
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id")
        ? VueCookieNext.getCookie("_ia_partner_id")
        : 0,
      sector: "",
      event_occupation: "",
      event_institute: "",
      geo_division_id: "",
      geo_district_id: "",
      district_name: "",
      geo_upazila_id: "",
      upazila_name: "",
      task_id: "",
      output_id: "",
      output: "",
      activity_id: "",
      activity: "",
      training_type: "",
      event_type: "",
      event_title: "",
      event_start_date: "",
      event_end_date: "",
      event_duration_day: "",
      event_duration_hour: "",
      total_instructor_male: "",
      total_instructor_female: "",
      total_participants_male: "",
      total_participants_female: "",
      total_participants_third_gender: "",
      total_participants_disable_male: "",
      total_participants_disable_female: "",
      total_ethnic_minorities_male: "",
      total_ethnic_minorities_female: "",
      venue: "",
      attendance_file: "",
      selected_ia_partners: [],
    });

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          formData.value = {
            ...formData.value,
            ...newData,
          };
        }
      }
    );

    const schema = object().shape({
      // component_id: number().required("Component is required"),
      event_type: string().required("Event Type is required"),
    });

    const images: Ref<File[]> = ref([]);

    const errors = ref({});
    const componentOptions = ref([]);
    const outputOptions = ref<OutputOption[]>([]);
    const activityOptions = ref<ActivityOption[]>([]);
    const occupationOptions = ref([]);
    const taskOptions = ref([]);
    const divisionOptions = ref([]);
    const districtOptions = ref<DistrictOption[]>([]);
    const upazilaOptions = ref<UpazilaOption[]>([]);
    const trainingField = ref(false);
    const beneficiaryList = ref<Beneficiary[]>([]);

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DISTRICT_LIST,
          {
            geo_division_id: formData.value.geo_division_id,
          }
        );
        districtOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.district_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDistrictWistUpazila = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_UPAZILA_LIST,
          {
            geo_district_id: formData.value.geo_district_id,
          }
        );
        upazilaOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.upazila_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getSectorWiseOccupation = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_OCCUPATION_LIST,
          {
            sector_name: formData.value.sector,
          }
        );

        occupationOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getOutput = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_OUTPUT_LIST,
          {
            ia_partner_id:
              VueCookieNext.getCookie("_ia_partner_id") !== "null"
                ? VueCookieNext.getCookie("_ia_partner_id")
                : 0,
          }
        );

        outputOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getComponentWiseActivity = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_ACTIVITY_LIST,
          {
            output_id: formData.value.output_id,
          }
        );

        activityOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const getActivityWiseTask = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_TASK_LIST,
          {
            activity_id: formData.value.activity_id,
          }
        );

        taskOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const addBeneficiary = async () => {
      try {
        const nidElement = document.getElementById("nid") as HTMLInputElement;

        const nid = nidElement ? nidElement.value : null;

        if (nid) {
          const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_BENEFICIARY_LIST,
            {
              nid: nid,
            }
          );

          const beneficiaries = response.data.data;

          beneficiaries.forEach((beneficiary) => {
            const beneficiaryData = {
              id: beneficiary.id,
              name: beneficiary.beneficiary_name,
              nid: beneficiary.nid,
            };

            beneficiaryList.value.push(beneficiaryData);
          });
        }
      } catch (error) {
        console.error("Error fetching beneficiaries:", error);
      }
    };

    watch(
      () => formData.value.output_id,
      (newComponent) => {
        if (newComponent !== "") {
          getComponentWiseActivity();
        }
      }
    );

    watch(
      () => formData.value.activity_id,
      (newComponent) => {
        if (newComponent !== "") {
          getActivityWiseTask();
        }
      }
    );

    watch(
      () => formData.value.geo_division_id,
      (newComponent) => {
        if (newComponent !== "") {
          getDivisionWistDistrict();
        }
      }
    );

    watch(
      () => formData.value.geo_district_id,
      (newComponent) => {
        if (newComponent !== "") {
          getDistrictWistUpazila();
        }
      }
    );

    watch(
      () => formData.value.sector,
      (newComponent) => {
        if (newComponent !== "") {
          getSectorWiseOccupation();
        }
      }
    );

    watch(
      () => formData.value.event_type,
      (newEventType) => {
        trainingField.value = newEventType === "Training";
      }
    );

    watch(
      () => [formData.value.event_start_date, formData.value.event_end_date],
      ([startDate, endDate]) => {
        if (startDate && endDate) {
          if (startDate > endDate) {
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Invalid Date Range",
              type: "success",
            });
            formData.value.event_end_date = "";
            return;
          }

          if (startDate == endDate) {
            formData.value.event_duration_day = "1";
          } else {
            const startTimestamp = new Date(startDate).getTime();
            const endTimestamp = new Date(endDate).getTime() + (1000 * 60 * 60 * 24); // Add one day in milliseconds
            const durationInMilliseconds = endTimestamp - startTimestamp;
            const durationInDays = Math.ceil(durationInMilliseconds / (1000 * 60 * 60 * 24));
            formData.value.event_duration_day = durationInDays.toString();
            let durationHour = durationInDays * 8
            formData.value.event_duration_hour = durationHour.toString();
          }
        }
      }
    );

    const save = async () => {
      try {

        submitButton.value?.setAttribute("data-kt-indicator", "on");

        if (formData.value && formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        const inputFile = document.getElementById('attendance_file') as HTMLInputElement;
        if (inputFile) {
          inputFile.value = '';
        }

        const selectedOutputId: string = formData.value.output_id;
        const selectedOutputText: string | undefined = outputOptions.value.find(
          (option) => option.value === selectedOutputId
        )?.text;

        if (selectedOutputText) {
          formData.value.output_id = selectedOutputId;
          formData.value.output = selectedOutputText;
        }

        const selectedActivityId: string = formData.value.activity_id;
        const selectedActivityText: string | undefined =
          activityOptions.value.find(
            (option) => option.value === selectedActivityId
          )?.text;

        if (selectedActivityText) {
          formData.value.activity_id = selectedActivityId;
          formData.value.activity = selectedActivityText;
        }

        const selectedDistrictId: string = formData.value.geo_district_id;
        const selectedDistrictText: string | undefined =
          districtOptions.value.find(
            (option) => option.value === formData.value.geo_district_id
          )?.text;

        if (selectedDistrictText) {
          formData.value.geo_district_id = selectedDistrictId;
          formData.value.district_name = selectedDistrictText;
        }

        const selectedUpazilaId: string = formData.value.geo_upazila_id;
        const selectedUpazilaText: string | undefined = upazilaOptions.value.find(
          (option) => option.value === formData.value.geo_upazila_id
        )?.text;
        if (selectedUpazilaText) {
          formData.value.geo_upazila_id = selectedUpazilaId;
          formData.value.upazila_name = selectedUpazilaText;
        }

        ApiService.setHeaderforImage();
        const formDataObj = new FormData();

        for (const key in formData.value) {
          if (formData.value.hasOwnProperty(key) && key !== 'attendance_file') {
            formDataObj.append(key, formData.value[key]);
          }
        }

        if (formData.value.attendance_file) {
          formDataObj.append('attendance_file', formData.value.attendance_file);
        }

        const imageFiles = Array.from(images.value);

        if (imageFiles.length > 0) {
          imageFiles.forEach((image, index) => {
            formDataObj.append(`event_file[${index}]`, image);
          });
        }
        if (!formData.value.event_type) {
          ElNotification({
            dangerouslyUseHTMLString: true,
            message: "Event Type is required",
            type: "success",
          })
        } else {

          ApiService.post(apiEndpoint.data().VUE_APP_EVENT_STORE, formDataObj)
            .then((response) => {
              submitButton.value?.removeAttribute("data-kt-indicator");
              console.log(response.data);
              if (response.data.status == "success") {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: response.data.data,
                  type: "success",
                });
                router.push("/event");
              } else {
                ElNotification({
                  dangerouslyUseHTMLString: true,
                  message: "Error, Something went wrong!",
                  type: "error",
                });

              }
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }

        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    const loadDataForUpdate = async () => {
      try {
        const id = route.params.id;
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_EVENT_INFO,
          {
            id: id,
          }
        );

        formData.value = {
          ...formData.value,
          ...response.data.data,
        };

        // Populate other form data properties as needed
      } catch (error) {
        console.error("Error loading data for update:", error);
      }
    };

    const uploadAttendanceFile = (event) => {
      formData.value.attendance_file = event.target.files[0];
    };

    const onFileChange = (event) => {
      const files = event.target.files;
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          images.value.push(file);
        }
      }
    };

    const getImageUrl = (image) => {
      return URL.createObjectURL(image);
    };

    const removeImage = (imgIndex) => {
      images.value.splice(imgIndex, 1);
    };

    watch(
      () => route.params.id,
      (newEventId) => {
        if (newEventId) {
          loadDataForUpdate();
        }
      }
    );

    onMounted(() => {
      if (route.params.id) {
        loadDataForUpdate();
      }
      getComponent();
      getOutput();
      getDivision();
    });

    return {
      formData,
      trainingField,
      componentOptions,
      occupationOptions,
      outputOptions,
      activityOptions,
      taskOptions,
      divisionOptions,
      districtOptions,
      upazilaOptions,
      addBeneficiary,
      beneficiaryList,
      errors,
      save,
      submitButton,
      uploadAttendanceFile,
      onFileChange,
      getImageUrl,
      images,
      removeImage
    };
  },
});
